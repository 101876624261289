/* .scrolling-images-container {
  position: relative;
  height: 100vh; 
  overflow: hidden;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: height 0.3s ease; 
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */



.card {
  width: 120px;
  height: 450px;
  border-radius: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  overflow: hidden;
  transition: 0.5s ease-in; 
  background-size: cover; 
  background-position: center;
  opacity: 0.8;
}

.card.active {
  width: 665px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.card:hover {
  transform: translateX(-100%);
}

.card.active:hover {
  transform: translateX(0%);
}
